<template>
    <div class="modal-body">
        <template v-if="loading">
            <i class="fas fa-spinner fa-spin"></i> Even wachten...
        </template>
        <template v-else>
            <FormItem id="customer_name" label="Klant Naam" v-model="customer.name" />
            <FormItem id="customer_status" label="Klant Status" :type="'select'" v-model="customer.customer_status_id" :options="customerStatusses"/>
            <!-- <FormItem id="kickback_fee" :type="'checkbox'" label="Kickback Fee"  v-model="customer.kickback_fee" /> -->
            <FormItem id="customer_partner" label="Partner" :type="'select'" v-model="customer.customer_partner_id" :options="customerPartners"/>
            <br>
            <h5>Factuur gegevens</h5>
            <FormItem id="financial_name" label="Naam" v-model="customer.financial_name" />
            <FormItem id="address" label="Adres" v-model="customer.address" />
            <FormItem id="postal_code" label="Postcode" v-model="customer.postal_code" />
            <FormItem id="city" label="Plaats" v-model="customer.city" />
            <FormItem id="email" label="E-mail" v-model="customer.email" />
            <FormItem id="phone_number" label="Telefoon nr" v-model="customer.phone_number" />
           
            <template v-if="customer.id">
                <hr class="hr" />

                <h5>Koppelingen ({{customer.connections ? customer.connections.length : 0}})</h5>
                <template v-for="connection in customer.connections" :key="connection.id">
                    {{connection.name}} ({{connection.billing_start_date ? formatDate(connection.billing_start_date, 'longDate') : ''}}) <template v-if="connection.automatic_transfers">&#x2705; </template><br>
                </template>

                <hr class="hr" />

                <h5>Notes ({{customer.customer_notes ? customer.customer_notes.length : 0}})</h5>
                <template v-if="customer.customer_notes">
                    <div class="container">
                        <br>
                        <div class="row">
                            <div>
                                <div class="comments-list">
                                    <template v-for="note in customer.customer_notes" :key="note.id">
                                        <div class="media">
                                            <p class="pull-right"><small
                                                    :title="formatDate(note.created_at, 'dateTime')">{{formatDate(note.created_at, "fromNow")}}</small>
                                            </p>
                                            <div class="media-body">
                                                <h4 class="media-heading user_name">{{note.user.name}}</h4>
                                                <pre style="text-wrap: wrap;">{{note.message}}</pre>
                                                <!-- <p><small><a href="">Edit</a> - <a href="">Delete</a></small></p> -->
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <br>
                <FormItem id="newMessage" label="Nieuwe comment" type="textarea" v-model="newMessage" />
                <button type="button" class="btn btn-success" style="float:right" @click="addMessage">Voeg comment toe</button>
            </template>
        </template>
    </div>
</template>

<script>
    import customerNoteService from '@/services/CustomerNoteService';
    import customerService from '@/services/CustomerService';

    export default {
        name: 'CustomerListComponent',
        props: ['itemData'],
        data() {
            return {
                customerStatusses: [],
                customerPartners: [],
                customer: {
                    'name': null,
                    'financial_name': null,
                    'address': null,
                    'postal_code': null,
                    'city': null,
                    'country_id': 158,
                    'email': null
                },
                submitted: false,
                loading: false,
                invalid: {},
                newMessage: '',
                
            }
        },
        watch: {
            itemData: function (val) {
                this.customer = val
            },
        },
        methods: {
            validate() {
                this.invalid = {};
                let valid = true;

                let reqsuired = document.querySelectorAll('[required]');

                return valid;
            },
            getData() {
                return this.customer
            },
            reset() {
                this.newMessage = ""
                this.customer = {
                        'name': null,
                        'financial_name': null,
                        'address': null,
                        'postal_code': null,
                        'city': null,
                        'country_id': 158,
                        'email': null
                    },
                    this.loading = false
                this.invalid = {}
            },
            setData(data) {
                this.customer = data
            },
            addMessage() {
                customerNoteService.store({"message": this.newMessage, "customer_id": this.customer.id}).then(response => {
                    this.customer.customer_notes.push(response.data)
                    this.newMessage = ""
                }).catch(error => {
                    this.toastError(error)
                })
            }
        },
        mounted() {
            this.reset()
            customerService.getStatusses().then(response => {
                this.customerStatusses = response.data
            }).catch(error => {
                this.toastError(error)
            })
            customerService.getPartners().then(response => {
                this.customerPartners = response.data
            }).catch(error => {
                this.toastError(error)
            })
        }
    }
</script>
<style scoped>
    .user_name {
        font-size: 14px;
        font-weight: bold;
    }

    .comments-list .media {
        border-bottom: 1px dotted #ccc;
    }

    .pull-right {
        float: right !important;
        font-weight: 400;
        line-height: 1;
        color: #777;
        font-size: 65%;
    }

    .media-body {
        margin: 10px 0px;
    }

    textarea {
        white-space: pre;
        overflow-wrap: normal;
        overflow-x: scroll;
    }
</style>