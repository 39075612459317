<template :if="isAdmin">
    <Overview 
        ref="Overview" 
        :moduleName="'Klant'"
        :moduleNamePlural="'Klanten'"
        :sort="['customer_status.order', 'name']"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :disableDelete=!isAdmin
        :disableNewButton=!isAdmin
    >
        <template #header >
            <FormItem type="checkbox" id="noConnection" :label="'Zonder Koppeling'" v-model="noConnection" :true-value="1" :false-value="0" @change="changeCustomerType(noConnection)"/>
        </template>
          
        <template #columns>
            <Column field="name" header="Naam" :sortable="true" style="width: 30%"></Column>
            <Column field="city" header="Plaats" :sortable="true" style="width: 15%"></Column>
            <Column field="customer_status.name" header="Status" :sortable="true" style="width: 15%" sortField="customer_status.order"></Column>
            
            <Column field="connections.id" header="Koppelingen" style="width: 10%">
                <template #body="slotProps">
                    <template v-if="slotProps.data.connections">
                        {{slotProps.data.connections.length}}
                    </template>
                </template>
            </Column>
            <Column field="customerNotes" header="Laatste comment" style="width: 40%">
                <template #body="slotProps">
                    <template v-if="slotProps.data.customer_notes[0]" >
                        <div style="overflow-y: auto; max-height:100px;" :title="formatDate(slotProps.data.customer_notes[0].created_at, 'dateTime')">
                        <div style="font-size:10px;line-height:8px;">{{slotProps.data.customer_notes[0].user.name}} {{formatDate(slotProps.data.customer_notes[0].created_at, 'fromNow')}}:</div>
                        {{slotProps.data.customer_notes[0].message}}
                        </div>
                    </template>
                </template>
            </Column>
            
        </template>
            


        <template #form>
            <CustomerForm ref="ItemForm" :itemData="item"/>
        </template>
    </Overview>
</template>

<script>
import customerService from '@/services/CustomerService';
import Column from 'primevue/column';
import CustomerForm from '@/components/customer/CustomerFormComponent.vue';
import Overview from '@/components/Overview.vue';
import store from '@/store/user'

export default {
    components: {
        Overview,
        Column,
        CustomerForm
    },
    data() {
        return {
            itemService: customerService,
            item: {},
            itemFormRef: null
        }
    },
    computed: {
        isAdmin () {
            return store.getters.isAdmin
        }
    },
    methods: {
        changeCustomerType(noConnection){
            this.$refs.Overview.addCustomFilter("noConnection", noConnection)
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>

<style scoped>

    .ms-n5 {
        margin-left: -40px;
    }

    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    a.fa-stack {
        cursor: pointer;
    }

</style>

<style>
.pi-sort-alt:before {
    content: "";
}
</style>