import {ApiService} from '@/services/ApiService'

const URL = '/api/customer-note'

class CustomerNoteService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
}

export default new CustomerNoteService()